<template>
  <v-container
    fill-height
    fluid
    class="lighten-5 text-center"
    style="position: relative"
  >
    <!-- Loader  -->
    <v-row align="center" class="text-center" justify="center" v-if="loading">
      <v-col class="mt-10">
        <v-progress-circular
          indeterminate
          color="error"
          class="my-5"
        ></v-progress-circular>
        <p caption>Loading... Please wait</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    loading: {},
  },
};
</script>
