<template>
  <div>
    <MainStepper e1="3" />
    <v-card class="px-3">
      <Loader :loading="loading" />
      <div v-if="pageReady">
        <v-row>
          <v-col cols="6">
            <v-toolbar-title
              class="
                primary--text
                text-uppercase
                font-weight-bold
                justify-center
                mt-n3
                pr-3
              "
            >
              <v-icon class="mr-2 mt-n1" color="error">mdi-domain</v-icon
              >Application Preview
            </v-toolbar-title>
            <p class="grey--text ml-8 mt-n2">
              Prequalification application Preview
            </p>
            <v-divider class="mt-n2"></v-divider>
          </v-col>
          <v-col cols="6">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="text-capitalize ml-1 float-right"
                  :loading="loading"
                  @click="goToSupplierSelection"
                  elevation="0"
                  fab
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-format-list-bulleted-square</v-icon>
                </v-btn>
              </template>
              <span>Back to supplier selection</span>
            </v-tooltip>
            <v-text-field
              label="search..."
              v-model="search"
              append-icon="mdi-magnify"
              dense
              width="54"
              color="primary"
              class="float-right"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td class="text-left text-uppercase font-weight-bold">
                  Application Attribute
                </td>
                <td class="text-left text-uppercase font-weight-bold">
                  Details
                </td>
                <v-divider></v-divider>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Applicant ID</td>
                <td>{{ application.applicant.id }}</td>
              </tr>
              <tr>
                <td>Company Name</td>
                <td>{{ application.applicant.company.company_name }}</td>
              </tr>
              <tr></tr>
              <tr>
                <td>Reference</td>
                <td>PREQ/APP/{{ application.id }}</td>
              </tr>
              <tr v-if="application.status">
                <td>Status</td>
                <td
                  v-if="application.status.slug == 'open'"
                  class="success--text"
                >
                  {{ application.status.name }}
                </td>
                <td
                  v-if="application.status.slug == 'closed'"
                  class="error--text"
                >
                  {{ application.status.name }}
                </td>
              </tr>
              <tr>
                <td>Documents</td>
                <td>
                  <div
                    class="py-1 my-3 justify-center"
                    v-for="(item, i) in application.documents"
                    :key="i"
                  >
                    <a
                      link
                      v-bind:href="item.url"
                      color="success"
                      class="text-decoration-underline py-5 font-weight-bold"
                      >({{ i + 1 }}) {{ item.name }}
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Categories</td>
                <td>
                  <div
                    class="py-1 my-1 justify-center"
                    v-for="(item, i) in application.categories"
                    :key="i"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          depressed
                          color="success darken-1 mx-2"
                          small
                          dark
                          text
                          v-if="user.is_admin"
                          v-bind="attrs"
                          v-on="on"
                          @click="previewDocuments(item.id, item.title)"
                        >
                          <v-icon
                            >mdi-arrow-down-bold-circle-outline</v-icon
                          ></v-btn
                        >
                      </template>
                      <span v-if="item.required_documents.length > 0">
                        Required Documents
                        <v-divider color="white"></v-divider>
                        <div
                          class="py-1 my-3 justify-center"
                          v-for="(req_item, i) in item.required_documents"
                          :key="i"
                        >
                          {{ i + 1 }}. {{ req_item.name }}
                        </div>
                      </span>
                      <span v-else> No required document </span>
                    </v-tooltip>
                    <a color="success" class="text-decoration font-weight-bold"
                      >({{ i + 1 }}) {{ item.title }}: {{ item.description }}
                    </a>
                  </div>
                </td>
              </tr>
              <tr v-if="user.is_admin">
                <td>Approve Categories</td>
                <td>
                  <v-col cols="12" sm="12" md="12">
                    <!-- required docs  -->
                    <v-col cols="12" sm="12" md="12" class="mt-2">
                      <v-select
                        v-model="editedItem.selected_categories"
                        :value="editedItem.selected_categories"
                        :rules="rules.select2"
                        :items="application.categories"
                        label="Available Categories"
                        item-text="title"
                        item-value="id"
                        multiple
                        outlined
                        chips
                        color="grey"
                        item-color="grey"
                        class="my-1 mx-1"
                        @change="handleCategories"
                      >
                        <template v-slot:label>
                          <span class="input__label"
                            >Select Categories
                            <v-icon small color="error" class="mt-n2"
                              >mdi-star-outline</v-icon
                            ></span
                          >
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="toggleRequired">
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  editedItem.required_documents.length > 0
                                    ? 'error'
                                    : ''
                                "
                              >
                                {{ iconRequired }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <v-list-item disabled>
                            <v-list-item-avatar color="grey lighten-3">
                              <v-icon> mdi-file-outline </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content v-if="likesAllCategories">
                              <v-list-item-title>
                                All Categories selected!
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-content
                              v-else-if="likesSomeCategories"
                            >
                              <v-list-item-title>
                                Document Count
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ editedItem.required_documents.length }}
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-content v-else>
                              <v-list-item-title>
                                Which categories is this supplier for?
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Go ahead, make a selection above!
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                  </v-col>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider class="my-2"></v-divider>
      </div>
    </v-card>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import TenderService from "../../services/tender.service";
import PrequalificationService from "../../services/prequalification.service";
import MainStepper from "../../components/MainStepper.vue";

export default {
  components: {
    Loader,
    MainStepper
  },
  props: {
    id: {},
  },
  data() {
    return {
      required_documents: [],
      selected_categories: [],
      editedItem: {
        required_documents: [],
        selected_categories: [],
      },
      categories: [],
      isUpdating: false,
      pageReady: false,
      loading: true,
      interest: {},
      valid: true,
      applyBtn: true,
      showInterestBtn: true,
      dialog: false,
      application_attribute: [],
      application: {
        clarifications_answered: 0,
        clarifications_count: 0,
      },
      fileToBeUploaded: {},
      docURI:
        "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
      documentFileToBeUploaded: {},
      requiredRules: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],

      rules: {
        select: [(v) => !!v || "An item should be selected"],
        select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
        required: [
          (v) => !!v || "Field is required",
          (v) => (v && v.length >= 3) || "Min 3 characters",
        ],
      },
    };
  },
  created() {
    this.getItem();
    this.setDetails(Object.entries(this.application));
  },
  isUpdating(val) {
    if (val) {
      setTimeout(() => (this.isUpdating = false), 3000);
    }
  },
  computed: {
    companyId() {
      return this.$store.state.auth.user.company_id;
    },
    user() {
      return this.$store.state.auth.user;
    },
    // required docs
    likesAllCategories() {
      return (
        this.editedItem.required_documents.length ===
        this.required_documents.length
      );
    },
    likesSomeCategories() {
      return (
        this.editedItem.required_documents.length > 0 &&
        !this.likesAllCategories
      );
    },
    iconRequired() {
      if (this.likesAllCategories) return "mdi-close-box";
      if (this.likesSomeCategories) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {

    goToSupplierSelection() {
      this.$router.push("/supplier-selection");
    },
    previewDocuments(id, name) {
      this.$router.push("/supplier-category-documents-view/" + id + "/" + name);
    },
    toggleRequired() {
      this.$nextTick(() => {
        if (this.likesAllCategories) {
          this.editedItem.required_documents = [];
        } else {
          this.editedItem.required_documents = this.required_documents.slice();
        }
      });
    },
    getInitials: function (str) {
      return str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
    },
    handleCategories() {
      console.log(this.editedItem.selected_categories);
      let data = {};
      data.categories = this.editedItem.selected_categories;
      data.user_id = this.application.applicant.id;
      console.log(data);
      PrequalificationService.addCategories(data).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Categories set successful!");
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    getSelectedCategories() {
      return PrequalificationService.getSelectedCategories(
        this.application.applicant.id
      ).then(
        (response) => {
          if (response.status == 200) {
            this.editedItem.selected_categories = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.editedItem.selected_categories = [];
          console.log(error);
        }
      );
    },
    clarificationPreview(item) {
      this.$router.push("/clarification/" + item.id);
    },
    getItem() {
      return PrequalificationService.show(this.id).then(
        (response) => {
          if (response.status == 200) {
            this.application = response.data.data;
            this.getSelectedCategories();
            this.loading = false;
            this.pageReady = true;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleFileUpload(file) {
      console.log("uploading...");
      let formData = {};
      formData["tender_id"] = this.application.id;
      formData["name"] = file.name;
      await this.getBase64(file).then((data) => (formData["content"] = data));
      TenderService.addDocument(formData).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Tender Document uploaded!");
            this.getItem();
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            this.message = error.response.data.message;
          } else {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString() ||
              "Internal Server Error";
          }
          this.$store.dispatch("alert/error", this.message);
          this.loading = false;
        }
      );
    },
    showInterest() {
      this.$refs.form.validate();
      if (!this.valid) {
        this.loading = false;
        return false;
      }
      this.loading = true;
      let formData = {};
      formData["content"] = this.interest.content;
      formData["tender_id"] = this.application.id;
      formData["applicant_id"] = this.user.id;
      TenderService.showInterest(formData).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Interest sent!");
            this.getItem();
            this.loading = false;
            this.dialog = false;
          } else {
            this.$store.dispatch("alert/error", response.message);
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.loading = false;
            this.message = error.response.data.message;
          } else {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString() ||
              "Internal Server Error";
          }
          this.$store.dispatch("alert/error", this.message);
        }
      );
    },
    setDetails(item) {
      item.forEach((element) => {
        this.application_attribute.push(element);
      });
    },
    
    toMoneyFormat(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    goToTender() {
      this.$router.push(
        "/tender-application-" +
          this.application.application_steps +
          "/" +
          this.application.id
      );
    },
    goToClarification() {
      this.$router.push("/clarification");
    },
  },
};
</script>
